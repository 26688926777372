
































































































































import useMisc from "@/use/misc";
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";
import validator from "validator";

export default defineComponent({
  components: {
    MEditor: () => import("@/components/molecules/editor/m-editor.vue"),
  },
  setup(_, { root }) {
    const { surveyAnswerType, goBack } = useMisc({ root });
    const form = ref<any>(null);

    const model = reactive({
      questions: [
        {
          name: "",
          answerType: "single choice",
          isEnabled: true,
          isAnswerRequired: false,
          additionalInformation: undefined,
          answers: [
            {
              name: "",
              isEnabled: true,
              reportValue: undefined,
              participantGroup: {
                id: undefined,
              },
            },
          ],
        },
      ],
    });

    const state = reactive({
      valid: true,
      loading: false,
      error: false as unknown,
      success: false,
      surveyName: root.$route.query.name,
      participantGroups: [],
    });

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 404:
          return `${root.$tc("layout.errors.404")}`;
        case 409:
          return `${root.$tc("event.panel.modules.surveyModule.error409")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/participant/group/select`)
        .then(({ data: { groups } }) => {
          state.participantGroups = groups.map((group: any) => ({ ...group }));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.participantGroups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchData);

    const onSubmit = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        questions: model.questions.map((el) => ({
          ...el,
          answers: el.answers.map((a) => ({
            ...a,
            name: a.name || "text",
            participantGroup: a.participantGroup.id
              ? a.participantGroup
              : undefined,
          })),
        })),
      };

      state.loading = true;

      axiosInstance
        .post(`survey/${root.$route.params.smid}/question`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.success = true;
          state.error = false;

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "primary",
            message: root.$tc(
              "event.panel.modules.surveyModule.question.success"
            ),
          });

          setTimeout(() => {
            goBack("panel.event.view.surveyModule.list");
          }, 500);
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        })
        .finally(() => {
          state.loading = false;
        });
    };

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
    };

    const addQuestion = () => {
      model.questions.push({
        name: "",
        answerType: "single choice",
        isEnabled: true,
        isAnswerRequired: false,
        additionalInformation: undefined,
        answers: [
          {
            name: "",
            isEnabled: true,
            reportValue: undefined,
            participantGroup: {
              id: undefined,
            },
          },
        ],
      });
    };

    const addAnswer = (index: any) => {
      model.questions[index].answers.push({
        name: "",
        isEnabled: true,
        reportValue: undefined,
        participantGroup: {
          id: undefined,
        },
      });
    };

    const removeQuestion = (index: number) => {
      model.questions.splice(index, 1);
    };

    const removeAnswer = (index: number, index2: number) => {
      model.questions[index].answers.splice(index2, 1);
    };

    return {
      form,
      model,
      state,
      onSubmit,
      rules,
      surveyAnswerType,
      addQuestion,
      addAnswer,
      removeQuestion,
      removeAnswer,
    };
  },
});
